import { IconBrandGithub } from '@tabler/icons-react'
import { useCookies } from 'react-cookie'
import { useEffect, useState } from 'react'
import { Octokit } from 'octokit'
import { useLocalStorage } from '@uidotdev/usehooks'

const popupParams = o => Object.keys(o).map(p => `${p}=${o[p]}`).join(',')

export function ButtonLoginGithub ({ scope = 'repo read:org read:user user:email', className = 'btn', children = <><IconBrandGithub /> Login</> }) {
  const [, setCookie] = useCookies(['gh'])

  const popupHeight = 650
  const popupWidth = 500

  const handleClick = () => {
    const p = popupParams({
      allowOpenerAccess: 'yes',
      scrollbars: 'no',
      resizable: 'no',
      status: 'no',
      location: 'no',
      toolbar: 'no',
      menubar: 'no',
      height: popupHeight,
      width: popupWidth,
      top: window.top.outerHeight / 2 + window.top.screenY - (popupHeight / 2),
      left: window.top.outerWidth / 2 + window.top.screenX - (popupWidth / 2)
    })
    const base = document.location.origin === 'http://localhost:5173' ? 'http://localhost:5173' : 'https://github.gummicube.workers.dev'
    const w = window.open(`${base}/api/github?scope=${encodeURIComponent(scope)}&redir=${encodeURIComponent(document.location.toString())}`, 'github-oauth-authorize', p)
    const r = setInterval(() => {
      if (w.location.origin === window.location.origin) {
        clearInterval(r)
        w.close()
        const d = Object.fromEntries(new URLSearchParams(w.location.search))
        if (!d.gh) {
          throw new Error('GH token was not returned.')
        }
        setCookie('gh', d.gh)
      }
    }, 100)
  }

  return <button onClick={handleClick} className={className}>{children}</button>
}

export function ButtonLogoutGithub ({ user, setUser, removeToken }) {
  const handleLogout = () => {
    setUser(null)
    removeToken()
  }
  return (
    <>
      <button className='btn' onClick={handleLogout}>
        <div className='avatar'>
          <div className='w-6 rounded-full'>
            <img src={user.avatar_url} alt={user.login} title={user.login} />
          </div>
        </div>
        Logout
      </button>
    </>
  )
}

export default function ButtonGithubUser ({ onLogin }) {
  const [cookies,, removeCookie] = useCookies(['gh'])
  const [user, setUser] = useLocalStorage('gh_user', null)
  let [gh, setGh] = useState()

  useEffect(() => {
    if (cookies.gh && !gh) {
      gh = new Octokit({ auth: cookies.gh })
      setGh(gh)
      onLogin && onLogin(gh)
    }
    if (cookies.gh && !user) {
      gh.rest.users.getAuthenticated().then(u => setUser(u.data))
    }
  }, [cookies, user])

  return user
    ? <ButtonLogoutGithub user={user} setUser={setUser} removeToken={() => removeCookie('gh')} />
    : <ButtonLoginGithub />
}
