import { useEffect, useState, useRef } from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'
import { IconTrash, IconPlus, IconAlertCircleFilled } from '@tabler/icons-react'

import ButtonGithubUser from '@/ButtonGithubUser'

const api = async (url, method = 'GET') => {
  const r = await fetch(`/api/admin${url}`, { method })
  const e = await r.json()
  if (!r.ok) {
    throw new Error(e.error)
  }
  return e
}

function ButtonAddAdmin ({ onSuccess, onError }) {
  const r = useRef()
  const [value, setValue] = useState('')

  const handleAction = e => {
    onError(false)
    e.preventDefault()
    api(`/user/${encodeURIComponent(value)}`, 'POST')
      .then(d => {
        r.current.close()
        onSuccess(d)
      })
      .catch(e => onError(e.message))
  }

  return (
    <>
      <dialog ref={r} className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>New Admin</h3>
          <p className='py-4'>
            <input value={value} onChange={e => setValue(e.target.value)} type='text' placeholder='Github Username' className='input input-bordered w-full' />
          </p>

          <form className='modal-action flex gap-2' method='dialog'>
            <button className='btn'>Cancel</button>
            <button onClick={handleAction} className='btn btn-primary'>Ok</button>
          </form>
        </div>
      </dialog>
      <button onClick={() => r.current.showModal()} className='btn btn-sm btn-success btn-circle btn-outline'><IconPlus /></button>
    </>
  )
}

function ButtonAddRedirect ({ onSuccess, onError }) {
  const r = useRef()
  const [value, setValue] = useState('')

  const handleAction = e => {
    onError(false)
    e.preventDefault()
    api(`/redirect/${encodeURIComponent(value)}`, 'POST')
      .then(d => {
        r.current.close()
        onSuccess(d)
      })
      .catch(e => onError(e.message))
  }

  return (
    <>
      <dialog ref={r} className='modal'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>New Auth Host</h3>
          <p className='py-4'>
            <input value={value} onChange={e => setValue(e.target.value)} type='text' placeholder='URL of Auth Host' className='input input-bordered w-full' />
          </p>
          <form className='modal-action flex gap-2' method='dialog'>
            <button className='btn'>Cancel</button>
            <button onClick={handleAction} className='btn btn-primary'>Ok</button>
          </form>
        </div>
      </dialog>
      <button onClick={() => r.current.showModal()} className='btn btn-sm btn-success btn-circle btn-outline'><IconPlus /></button>
    </>
  )
}

function ButtonDeleteRedirect ({ value, onSuccess, onError }) {
  const handleAction = () => {
    onError(false)
    api(`/redirect/${encodeURIComponent(value)}`, 'DELETE')
      .then(onSuccess)
      .catch(e => onError(e.message))
  }
  return <button onClick={handleAction} className='btn btn-error btn-circle btn-outline'><IconTrash /></button>
}

function ButtonDeleteAdmin ({ value, onSuccess, onError }) {
  const handleAction = () => {
    onError(false)
    api(`/user/${encodeURIComponent(value)}`, 'DELETE')
      .then(onSuccess)
      .catch(e => onError(e.message))
  }
  return <button onClick={handleAction} className='btn btn-error btn-circle btn-outline'><IconTrash /></button>
}

function PageLoggedOut () {
  return 'You must login to use this tool.'
}

function PageLoggedIn ({ user }) {
  const [users, setUsers] = useState([])
  const [redirects, setRedirects] = useState([])
  const [error, setError] = useState()

  useEffect(() => {
    if (user) {
      fetch('/api/admin/users').then(r => r.json()).then(setUsers)
      fetch('/api/admin/redirects').then(r => r.json()).then(setRedirects)
    }
  }, [user])

  return users.length
    ? (
      <>
        {error && (
          <div role='alert' className='alert alert-error mb-8'>
            <IconAlertCircleFilled />
            <span>{error}</span>
          </div>
        )}
        <h2 className='text-lg mb-2 flex items-center gap-2'>
          <ButtonAddAdmin onSuccess={setUsers} onError={setError} />
          Admins
        </h2>
        <div className='overflow-x-auto'>
          <table className='table table-zebra table-pin-rows table-pin-cols'>
            <thead>
              <tr>
                <th />
                <td>Name</td>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map(admin => (
                <tr key={admin}>
                  <td>{admin !== user.login && (
                    <ButtonDeleteAdmin value={admin} onSuccess={setUsers} onError={setError} />
                  )}
                  </td>
                  <td>{admin}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h2 className='text-lg mb-2 mt-4 flex items-center gap-2'>
          <ButtonAddRedirect onSuccess={setRedirects} onError={setError} />
          Authorized Redirect Hosts
        </h2>
        <div className='overflow-x-auto'>
          <table className='table table-zebra table-pin-rows table-pin-cols'>
            <thead>
              <tr>
                <th />
                <td>URL</td>
                <th />
              </tr>
            </thead>
            <tbody>
              {redirects.map(redirect => (
                <tr key={redirect}>
                  <td><ButtonDeleteRedirect value={redirect} onSuccess={setRedirects} onError={setError} /></td>
                  <td>{redirect}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
      )
    : 'Please wait'
}

export default function App () {
  const [user] = useLocalStorage('gh_user', null)

  return (
    <div className='h-full w-full flex bg-base-100 '>
      <div className='p-4 flex gap-2 items-center justify-between fixed w-full left-0 top-0 z-10 h-20 bg-neutral border-b border-neutral-500/20'>
        <div className='text-xl flex gap-2 items-center'>
          <img src='/favicon.ico' alt='' /> Gummicube Github
        </div>
        <div className='flex gap-2 '>
          <ButtonGithubUser />
        </div>
      </div>

      <div className='mt-20 p-4 w-full'>
        {user && <PageLoggedIn user={user} />}
        {!user && <PageLoggedOut />}
      </div>
    </div>
  )
}
